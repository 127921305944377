<script>
    /**
     * @typedef {string | number} TreeNodeId
     * @typedef {{ id: TreeNodeId; text: string; disabled?: boolean; expanded?: boolean; }} TreeNode
     */

    /** @type {Array<TreeNode & { children?: TreeNode[] }>} */
    export let children = []
    export let expanded = false
    export let root = false

    /** @type {string | number} */
    export let id = ""
    export let text = ""
    export let disabled = false
    export let dragdrop=false
    export let icon_height=12

    /**
     * Specify the icon from `carbon-icons-svelte` to render
     * @type {typeof import("carbon-icons-svelte").CarbonIcon}
     */
    export let icon = undefined;
    export let prependIcons = undefined;
    export let _depth
    export let showSlot

    import { afterUpdate, getContext } from "svelte";
    //import CaretDown16 from "carbon-icons-svelte/lib/CaretDown16/CaretDown16.svelte";
    import TreeViewNode, { computeTreeLeafDepth } from "./treeviewnode.svelte";

    let ref = null;
    let refLabel = null;
    let prevActiveId = undefined;
    let node
    const {
        activeNodeId,
        selectedNodeIds,
        expandedNodeIds,
        clickNode,
        rightClickNode,
        selectNode,
        expandNode,
        focusNode,
        toggleNode,
        host
    } = getContext("TreeView");

    const offset = () => {
        const depth = computeTreeLeafDepth(refLabel);

        if (parent) return depth + 1;
        if (icon) return depth + 2;
        return depth + 2.5;
    };

    afterUpdate(() => {
        if (id === $activeNodeId && prevActiveId !== $activeNodeId) {
            if (!$selectedNodeIds.includes(id)) selectNode(node);
        }

        prevActiveId = $activeNodeId
    })

    $: parent = Array.isArray(children);
    $: node = { id, text, expanded, leaf: !parent }
    $: if (refLabel) {
        refLabel.style.marginLeft = `-${offset()}rem`
        refLabel.style.paddingLeft = `${offset()}rem`
    }
    $: expanded = $expandedNodeIds.includes(id)

    function dragStart(event,id) {
        event.dataTransfer.effectAllowed = 'move'
        event.dataTransfer.dropEffect = 'move'
        event.dataTransfer.setData("id", id)
        return true
    }
    let dropType=""
    // eslint-disable-next-line no-unused-vars
    function dragEnter(event,id) {

    }
    // eslint-disable-next-line no-unused-vars
    function dragLeave(event,id) {
        dropType=""

    }
    // eslint-disable-next-line no-unused-vars
    function dragOver(event,id) {
        event.dataTransfer.dropEffect = 'move'
        let currentRect = event.target.getBoundingClientRect()
        let y = event.clientY - currentRect.top
        if (y<currentRect.height/2 ) {
            dropType="before"
        } else {
            dropType="after"
        }
        event.preventDefault()
        return false
    }
    function drop(event,id) {
        let from=event.dataTransfer.getData("id")
        host.dispatchEvent(new CustomEvent('dropInternal', {detail:{from: from,to:id,type:dropType }}))
        dropType=""
    }
    let ulOpen="<ul>"
    let ulClose="</ul>"
</script>
{#if root}
    {#each children as child (child.id)}
        {#if Array.isArray(child.children)}
            <svelte:self   {dragdrop} {...child} icon_height={icon_height}><slot slot="content" name="content"/></svelte:self>
        {:else}
            <TreeViewNode   {dragdrop} leaf {...child} icon_height={icon_height}><slot slot="content" name="content"/></TreeViewNode>
        {/if}
    {/each}
{:else}
    <li
            bind:this="{ref}"
            role="treeitem"
            id="{id}"
            tabindex="{disabled ? undefined : -1}"
            aria-current="{id === $activeNodeId || undefined}"
            aria-selected="{disabled ? undefined : $selectedNodeIds.includes(id)}"
            aria-disabled="{disabled}"
            class:node="{true}"
            class:parent-node="{true}"
            class:node--active="{id === $activeNodeId}"
            class:node--selected="{$selectedNodeIds.includes(id)}"
            class:node--disabled="{disabled}"
            class:node--with-icon="{icon}"
            aria-expanded="{expanded}"
            on:click|stopPropagation="{(e) => {
      if (disabled) return;
      clickNode(node,e);
    }}"
            on:keydown="{(e) => {
                if (!e.target) return
                if (e.target.tagName==="INPUT") return
      if (
        e.key === 'ArrowLeft' ||
        e.key === 'ArrowRight' ||
        e.key === 'Enter'
      ) {
        e.stopPropagation();
      }

      if (parent && e.key === 'ArrowLeft') {
        expanded = false;
        expandNode(node, false);
        toggleNode(node);
      }

      if (parent && e.key === 'ArrowRight') {
        if (expanded) {
          ref.lastChild.firstChild.focus();
        } else {
          expanded = true;
          expandNode(node, true);
          toggleNode(node);
        }
      }

      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        if (disabled) return;
        expanded = !expanded;
        toggleNode(node);
        clickNode(node);
        expandNode(node, expanded);
        ref.focus();
      }
    }}"
            on:focus="{() => {
      focusNode(node);
    }}"
    on:contextmenu|stopPropagation={rightClickNode}
            style="position:relative"

    >
        <div     ondragover="return false"
                 class:dropzoneActive="{dropType==='before'}"
                 class:dropzone="{dropType!=='before'}"
                 style="top:0;pointer-events: none;"></div>

        <div class:node__label="{true}" bind:this="{refLabel}"

             draggable="{dragdrop}"
             on:dragover={event =>  dragOver(event, id)}
             on:dragstart={event => dragStart(event, id)}
             on:dragenter={event => dragEnter(event, id)}
             on:dragleave={event => dragLeave(event, id)}
             on:drop={event => drop(event, id,ref)}

        >
      <span
              class:parent-node__toggle="{true}"
              disabled="{disabled}"
              on:click="{() => {
          if (disabled) return;
          expanded = !expanded;
          expandNode(node, expanded);
          toggleNode(node);
        }}"
      >

        <span class="arrow   {expanded &&
            'arrowDown'}">▶</span>

      </span>
            <span class:node__label__details="{true}">
                {#if icon}
                    <fds-icon class="node__icon" name={icon} height={icon_height} ></fds-icon>
                {/if}
                {@html text}
                {#if prependIcons}
                    {#each prependIcons as pIcon}<fds-icon  name={pIcon.icon} height={icon_height} style="margin-left:5px;--color:{pIcon.color}"></fds-icon>{/each}
                {/if}                
      </span>
        </div>
        <div     ondragover="return false"
                 class:dropzoneActive="{dropType==='after'}"
                 class:dropzone="{dropType!=='after'}"
                 style="bottom:0;pointer-events: none;"></div>
        {#if expanded}
            <ul role="group" class:node__children="{true}">
                {#each children as child (child.id)}
                    {#if Array.isArray(child.children)}
                        <svelte:self {dragdrop} {...child} icon_height={icon_height}><slot slot="content" name="content"/></svelte:self>
                    {:else}
                        <TreeViewNode {dragdrop} leaf {...child} icon_height={icon_height} ><slot slot="content" name="content"/></TreeViewNode>
                    {/if}
                {/each}
            </ul>
        {/if}
    </li>

    {#if showSlot}
        <li>
            <div class="slotholder" style="margin-left:-{1.4*(_depth-1)}rem">
                <slot name="content"></slot>
            </div>
        </li>
    {/if}

{/if}
