<svelte:options tag="fds-treeview" accessors></svelte:options>
<script>

    /**
     * @typedef {string | number} TreeNodeId
     * @typedef {{ id: TreeNodeId; text: string; icon?: typeof import("carbon-icons-svelte").CarbonIcon; disabled?: boolean; expanded?: boolean; }} TreeNode
     */

    /**
     * Data in with structures with children as sub array
     * @type {Array<TreeNode & { children?: TreeNode[] }>}
     */
    export let children = [];

    /**
     * Set the current active node id
     * Only one node can be active
     * @type {TreeNodeId}
     */
    export let activeid = ""
    /**
     * Allow drag & drop of tree nodes. it fires drop event after a successfully drop
     * with from and to nodes and where it has been dropped: "before", "in" or "after".
     * Default tree manipulation can be avoided by event.preventDefault()
      * @type {boolean}
     */
    export let dragdrop=false
    /**
     * Set the node ids to be selected
     * @type {TreeNodeId[]}
     */
    export let selectedids = []
    /**
     * Set Id of tree element to be selected
     * @type {TreeNodeId}
     */
    export let selected = null;
    /**
     * Set Id of tree element to be open
     * @type {TreeNodeId}
     */
    export let open = null;

    /**
     * Tree data
     * @type {[]}
     */

    export let data = null;

    /**
     * Set the node ids to be expanded, all parent node ids must be expanded as well
     * @type {TreeNodeId[]}
     */
    export let expandedids = [];

    /**
     * Specify the TreeView size
     * @type {"default" | "compact"}
     */
    export let size = "default";

    /**
     *  Specify the label text
     * @type {string}
     */
    export let labeltext = "";


    /**
     * Set to `true` to visually hide the label text
     * @type {boolean}
     */
    export let hidelabel = false;
    /**
     * activate demo mode
     * @type {boolean}
     */
    export let demo =true

    /**
     * height for all icons
     * @type {integer}
     */
    export let icon_height=12

    let demoData= [
        {id:2,text:"BPM",icon: "fas cog",children: [
                {id:3,text:"Enterprise Workflow"},
                {id:4,text:"Workflow for Universities"},
                {id:5, text:"Helpdesk"}
            ], showSlot:true
        },
        {id:6,text:"CMS",children: [
                {id:7,text:"Enterprise Content Management"},
                {id:8,text:"Powerslave Rapid Prototyping Server"},
                {id:9,text:"Intranet Apps"}
            ]},
        {id:10,text:"Social",children: [
                {id:11,text:"Enterprise Social Network"},
                {id:12,text:"Wiki"},
                {id:13,text:"Community Server"}
            ]},
        {id:14,text:"Other",children: [
                {id:15,text:"Laboratory Database",children: [
                    {id:40,text:"Sub entry 1"},
                    {id:41,text:"Sub entry 2"},                
                ]},
                {id:16,text:"Quality Check in Development"},
                {id:17,text:"flying dog UI"}
            ]},
        {
            id: 18, text: "Technology used", children: [
                {id: 19, text: "PHP"},
                {id: 20, text: "Node.js"},
                {id: 21, text: "Web Components"},
                {id: 22, text: "Svelte"},
                {id: 23, text: "Vue"},
                {id: 24, text: "React"},
                {id: 25, text: "WebGL"},
                {id: 26, text: "Docker, Kubernets"},
                {id: 27, text: "MySQL, Postgres, Oracle"},
                {id: 28, text: "Windows, Linux, Cloud"},
                {id: 29, text: "Git+CI"},
                {id: 30, text: "PyTorch/Tensorflow"},
                {id: 31, text: "STM32/Arduino", icon: "fas cog"}
            ]
        }
    ]


    import { createEventDispatcher, setContext, onMount ,beforeUpdate} from "svelte";
    import { writable } from "svelte/store";
    import TreeViewNodeList from "./treeviewnodelist.svelte";
    import {stylestr} from './styles';
    import { get_current_component } from 'svelte/internal';
    import fdsHelper from "@fds-components/fds-helper"
    import {name, version as componentversion} from '../package.json';
 //   const path = fdsHelper.get_href()

    let host = get_current_component()
    let svelteDispatch = createEventDispatcher();
    let demodata = false;
    let demolabeltext="flying dog software";
    const dispatch = (name, detail) => {
        svelteDispatch(name, detail)
        host.dispatchEvent && host.dispatchEvent(new CustomEvent(name, { detail }))
    }

    /**
     * Get information about component
     * @param  {("api" | "examples" | "css")} type the info type
     */
    export async function getInfo(type) {
        if (type==="version"){
            return new Promise(resolve => {
                resolve(componentversion);
            });
        }
        let res = await fdsHelper.getInfo(type,name);
        return res;
    }

    /**
     * version of component
     * @type {string}
     */
    export const version = componentversion;

    const labelId = `label-${Math.random().toString(36)}`
    const activeNodeId = writable(activeid)
    const selectedNodeIds = writable(selectedids)
    const expandedNodeIds = writable(expandedids)
    let stylestring = stylestr
    let ref = null
    let treeWalker = null
    let styleel
    let styleinitialized = false
    let parentsarray = []


    /**
     * Triggered when the item is clicked - selected
     * @event select
     */
    setContext("TreeView", {
        activeNodeId,
        selectedNodeIds,
        expandedNodeIds,
        host,
        clickNode: (node,e) => {
            if (e.target.classList && e.target.classList.contains("frontIcon")) return
            if (e.target.classList && e.target.classList.contains("arrow")) return
            activeid = node.id;
            selectedids = [node.id];
            node.target = e.target.closest('.node__label');
            dispatch("select", {node});
        },
        /**
         * right button click on node
         * @event rightclick
         */
        rightClickNode: (e) => {
            let elid = null;
            if (e.path && e.path.length){
                e.path.forEach(el=>{
                    if(el.nodeName==="LI" && el.getAttribute('role')==='treeitem'){
                        elid = parseInt(el.getAttribute('id'));
                        e.elementid =  elid;
                        return;
                    }
                });
            }
            if (!elid && e.originalTarget){

                let el = e.originalTarget;
                while (el.parentElement && el.parentElement.nodeName!=="BODY") {
                    if(el.nodeName==="LI" && el.getAttribute('role')==='treeitem'){
                        elid = parseInt(el.getAttribute('id'));
                        e.elementid =  elid;
                        break;
                    }
                    el = el.parentElement;
                }
            }
            //console.log("right click",e);
            dispatch("rightclick", {e});
        },

        selectNode: (node) => {
            selectedids = [node.id];
        },
        expandNode: (node, expanded) => {
            if (expanded) {
                expandedids = [...expandedids, node.id];
            } else {
                expandedids = expandedids.filter((_id) => _id !== node.id);
            }
        },
        focusNode: () => {},
        /**
         * Toggle tree element
         * @event toggle
         */
        toggleNode: (node) => dispatch("toggle", node),
    })

    function translateDataToTree(data) {
        let parents = data.filter(value => value.parentId === 'undefined' || value.parentId == null)
        let children = data.filter(value => value.parentId !== 'undefined' && value.parentId != null)
        let translator = (parents, children) => {
            parents.forEach((parent) => {
                    children.forEach((current, index) => {
                            if (current.parentId === parent.id) {
                                let temp = JSON.parse(JSON.stringify(children))
                                temp.splice(index, 1)
                                translator([current], temp)
                                typeof parent.children !== 'undefined' ? parent.children.push(current) : parent.children = [current]
                            }
                        }
                    )
                }
            )
        }
        translator(parents, children)
        return parents
    }
    let idToEntry={}
    let bfs = function(tree, key, collection) {
        if (!tree[key] || tree[key].length === 0) return
        for (let i=0; i < tree[key].length; i++) {
            let child = tree[key][i]
            idToEntry[child.id]=child
            collection[child.id] = tree.id
            bfs(child, key, collection)
        }
    }
    /**
     * add _depth field to the whole tree (0=root)
     * @param subTree
     * @param depth
     */
    function addDepth(subTree,depth=1) {
        if (!subTree) subTree=children
        for (let node of subTree) {
            node._depth=depth
            if (node.children) addDepth(node.children,depth+1)
        }
    }




    function handleKeyDown(e) {
        if (!e.target) return
        if (e.target.tagName==="INPUT") return
        if (e.key === "ArrowUp" || e.key === "ArrowDown") e.preventDefault();

        treeWalker.currentNode = e.target;

        let node = null;

        if (e.key === "ArrowUp") node = treeWalker.previousNode();
        if (e.key === "ArrowDown") node = treeWalker.nextNode();
        if (node && node !== e.target) {
            node.tabIndex = "0";
            node.focus();
        }
    }

    function getParents(el){
        let result = []
        let currel = parseInt(el)
        result.push(currel)
        while(parentsarray[currel]){
            result.push(parentsarray[currel])
            currel = parentsarray[currel]
        }
        return result
    }

    beforeUpdate(() => {
        if (styleel && stylestring && !styleinitialized) {
            styleel.innerHTML = stylestring;
            styleinitialized = true;
        }
    })

    onMount(() => {

        const firstFocusableNode = ref.querySelector(
            "li.node:not(.node--disabled)"
        );

        if (firstFocusableNode != null) {
            firstFocusableNode.tabIndex = "0";
        }

    });
    $:{
        if(dragdrop==='false') dragdrop = false
        dragdrop = !!dragdrop;
    }
    $:{
        if(demo==='false') demo = false;   
        demo = !!demo
    }
    $:   if (demo) {
        children=demoData
        labeltext=demolabeltext
        demodata = true;
    }
    $: if (data && (!children.length || demodata)){
        demodata = false;
        data = data.map(({parent,...rest})=>{
                let tmp = {...rest}
                if (parent) tmp.parentId = parent
                return tmp
        })
        if(labeltext===demolabeltext) labeltext=""
        children = translateDataToTree(data)
    }
    $: if(children && children.length){
        parentsarray=[]
        idToEntry={}
        bfs({children}, "children", parentsarray)
        addDepth()
    }
    $: selectedids= [parseInt(selected)]
    $: activeid = parseInt(selected)
    $: if (open && children.length) {
        expandedids = getParents(open)
        //expandedids = [parseInt(open)];
    }
    $: activeNodeId.set(activeid)
    $: selectedNodeIds.set(selectedids)
    $: expandedNodeIds.set(expandedids)
    $: if (ref) {
        treeWalker = document.createTreeWalker(ref, NodeFilter.SHOW_ELEMENT, {
            acceptNode: (node) => {
                if (node.classList.contains("node--disabled"))
                    return NodeFilter.FILTER_REJECT;
                if (node.matches("li.node")) return NodeFilter.FILTER_ACCEPT;
                return NodeFilter.FILTER_SKIP;
            },
        });
    }

    function findInChildren(parentEntry,id) {
        let found=-1
        for(let i=0;i<parentEntry.children.length;i++) {
            let child=parentEntry.children[i]
            if (parseInt(child.id)===parseInt(id)) {
                found=i
            }
        }
        return found
    }
    host.addEventListener("dropInternal",(e)=>{
        let o=e.detail
        let fromEntry=idToEntry[o.from]
        let toEntry=idToEntry[o.to]
        function checkAllowed(entry,id) {
            if (!entry.children) return true
            if (entry.id===id) return false
            for (let i=0;i<entry.children.length;i++) {
                let child=entry.children[i]
                if (child.id===id) return false
                if (!checkAllowed(child,id)) return false
            }
            return true
        }
        if (!checkAllowed(fromEntry,o.to)) {        // drop allowed?
            return
        }
        let canceled =!host.dispatchEvent(new CustomEvent('drop', {detail:{from: fromEntry,to:toEntry,type:o.type },cancelable: true}))
        if (canceled ) return
        /**
         * execute drop now
         */
        let fromParentID=parentsarray[o.from]
        let fromParentEntry=idToEntry[fromParentID]
        if (!fromParentEntry) fromParentEntry={children:children}
        let toParentID=parentsarray[o.to]
        let toParentEntry=idToEntry[toParentID]
        if (!toParentEntry) toParentEntry={children:children}

        let found=findInChildren(fromParentEntry,o.from)
        if (found<0) return
        fromParentEntry.children.splice(found,1)
        if (!fromParentEntry.children.length) fromParentEntry.children=null
        found=findInChildren(toParentEntry,o.to)
        if (found<0) return
        if (o.type==="in") {
            if (toEntry.children) return
            toEntry.children=[fromEntry]
        }
        if (o.type==="before") {
            toParentEntry.children.splice(found,0,fromEntry)
        }
        if (o.type==="after") {
            toParentEntry.children.splice(found+1,0,fromEntry)
        }
        parentsarray=[]
        bfs({children}, "children", parentsarray)
        children=children
    })



</script>
{#if !hidelabel}
    <!-- svelte-ignore a11y-label-has-associated-control -->
    <label id="{labelId}" class:bx--label="{true}">
        <slot name="labeltext">{@html labeltext}</slot>
    </label>
{/if}
<ul
        {...$$restProps}
        role="tree"
        bind:this="{ref}"
        class:tree="{true}"
        class:tree--default="{size === 'default'}"
        class:tree--compact="{size === 'compact'}"
        aria-label="{hidelabel ? labeltext : undefined}"
        aria-labelledby="{!hidelabel ? labelId : undefined}"
        aria-multiselectable="{selectedids.length > 1 || undefined}"
        on:keydown
        on:keydown|stopPropagation="{handleKeyDown}"
>

    <TreeViewNodeList root children={children} icon_height={icon_height} {dragdrop}><slot slot="content" name="content"/></TreeViewNodeList>
</ul>

{#if stylestring}
    <style bind:this={styleel}/>
{/if}
