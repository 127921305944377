<script context="module">

    /**
     * Computes the depth of a tree leaf node relative to <ul role="tree" />
     * @param {HTMLLIElement} node
     * @returns {number} depth
     */
    export function computeTreeLeafDepth(node) {
        let depth = 0;
        if (node == null) return depth

        let parentNode = node.parentNode

        while (parentNode != null && parentNode.getAttribute("role") !== "tree") {
            parentNode = parentNode.parentNode
            if (parentNode.tagName === "LI") depth++
        }

        return depth;
    }

    /**
     * Finds the nearest parent tree node
     * @param {HTMLElement} node
     * @returns {null | HTMLElement}
     */
    function findParentTreeNode(node) {
        if (node.classList.contains("parent-node")) return node
        if (node.classList.contains("tree")) return null
        return findParentTreeNode(node.parentNode)
    }
</script>

<script>
    /**
     * @typedef {string | number} TreeNodeId
     */

    export let leaf = false

    /** @type {TreeNodeId} */
    export let id = ""
    export let text = ""
    export let disabled = false
    export let dragdrop=false
    export let icon_height=12

    /**
     * Specify the icon from `carbon-icons-svelte` to render
     * @type {typeof import("carbon-icons-svelte").CarbonIcon}
     */
    export let icon = undefined
    export let prependIcons=undefined
    export let _depth
    export let showSlot

    import {afterUpdate, getContext} from "svelte"

    let ref = null
    let refLabel = null
    let prevActiveId = undefined

    const {
        activeNodeId,
        selectedNodeIds,
        clickNode,
        rightClickNode,
        selectNode,
        focusNode,
        host,
    } = getContext("TreeView")
    const offset = () =>
        computeTreeLeafDepth(refLabel) + (leaf && icon ? 2 : 2.5);
    afterUpdate(() => {
        if (id === $activeNodeId && prevActiveId !== $activeNodeId) {
            if (!$selectedNodeIds.includes(id)) selectNode(node);
        }

        prevActiveId = $activeNodeId;
    });

    $: node = { id, text, expanded: false, leaf };
    $: if (refLabel) {
        refLabel.style.marginLeft = `-${offset()}rem`;
        refLabel.style.paddingLeft = `${offset()}rem`;
    }
    function dragStart(event,id) {
        if (!dragdrop) {
            event.preventDefault()
            return false
        }
        event.dataTransfer.effectAllowed = 'move'
        event.dataTransfer.dropEffect = 'move'
        event.dataTransfer.setData("id", id)
        return true
    }
    let dropType=""
    // eslint-disable-next-line no-unused-vars
    function dragEnter(event,id) {

    }
    // eslint-disable-next-line no-unused-vars
    function dragLeave(event,id) {
        dropType=""

    }
    // eslint-disable-next-line no-unused-vars
    function dragOver(event,id) {

        event.dataTransfer.dropEffect = 'move'
        let currentRect = event.target.getBoundingClientRect()
        let y = event.clientY - currentRect.top
        if (y<(currentRect.height/3) ) {
            dropType="before"
        } else {
            dropType="in"
        }
        if (y>currentRect.height-(currentRect.height/3) ) {
            dropType="after"
        }
        event.preventDefault()
        return false
    }
    function drop(event,id) {
        let from=event.dataTransfer.getData("id")
        host.dispatchEvent(new CustomEvent('dropInternal', {detail:{from: from,to:id,type:dropType }}))
        dropType=""
    }
    let ulOpen="<ul>"
    let ulClose="</ul>"

</script>
<li
        bind:this="{ref}"
        role="treeitem"
        id="{id}"
        tabindex="{disabled ? undefined : -1}"
        aria-current="{id === $activeNodeId || undefined}"
        aria-selected="{disabled ? undefined : $selectedNodeIds.includes(id)}"
        aria-disabled="{disabled}"
        class:node="{true}"
        class:leaf-node="{true}"
        class:node--active="{id === $activeNodeId}"
        class:node--selected="{$selectedNodeIds.includes(id)}"
        class:node--disabled="{disabled}"
        class:node--with-icon="{icon}"
        on:contextmenu|stopPropagation={rightClickNode}
        on:click|stopPropagation="{(e) => {
    if (disabled) return;
    clickNode(node,e);
  }}"
        on:keydown="{(e) => {
                            if (!e.target) return
                if (e.target.tagName==="INPUT") return
    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Enter') {
      e.stopPropagation();
    }

    if (e.key === 'ArrowLeft') {
      const parentNode = findParentTreeNode(ref.parentNode);
      if (parentNode) parentNode.focus();
    }

    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      if (disabled) return;
      clickNode(node,e);
    }
  }}"
        on:focus="{() => {
    focusNode(node);
  }}"
        style="position:relative"
>
    <div     ondragover="return false"
                class:dropzoneActive="{dropType==='before'}"
             class:dropzone="{dropType!=='before'}"

             style="top:0;pointer-events: none;"></div>
    <div        bind:this="{refLabel}" class:node__label="{true}"
                draggable="{dragdrop}"
                on:dragover={event =>  dragOver(event, id)}
                on:dragstart={event => dragStart(event, id)}
                on:dragenter={event => dragEnter(event, id)}
                on:dragleave={event => dragLeave(event, id)}
                on:drop={event => drop(event, id,ref)}

                class:dragEnterNode="{dropType==='in'}"
                class:dragNode="{dropType!=='in'}"
    >
        {#if icon}
            <fds-icon name="{icon}" height={icon_height} class="node__icon"></fds-icon>
        {/if} 
        {@html text}
        {#if prependIcons}
            {#each prependIcons as pIcon}<fds-icon  name={pIcon.icon} height={icon_height} style="margin-left:5px;--color:{pIcon.color}"></fds-icon>{/each}
        {/if}     
    </div>
    <div     ondragover="return false"
                class:dropzoneActive="{dropType==='after'}"
             class:dropzone="{dropType!=='after'}"
                 style="bottom:0;pointer-events: none;"></div>
</li>

    {#if showSlot}
        <li>
            <div class="slotholder" style="margin-left:-{1.4*(_depth-1)}rem">
                <slot name="content"></slot>
            </div>
        </li>
    {/if}
