export let stylestr = `

* { box-sizing: border-box; }

a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video {
    /*! padding:0; */border:0;
    margin:0;
    font:inherit;
    font-size:100%;
    vertical-align:baseline
}
span {
 cursor:pointer;
}
div {
 cursor:pointer;
}
.arrow {
 cursor:pointer;
 display:inline-block
}

.arrowDown {
    transform:rotate(90deg);
}

.dropzoneActive {
 height: 1px;
 width: 150px;
 background-color: var(--drop-color,blue);
 position: absolute;
}
.dragEnterNode {
    border: 1px solid var(--drop-color,blue);
}
.dragNode {
    border: 1px solid transparent;
}
ol, ul {
    padding: 0;
    border: 0;
    margin: 0;
    font: inherit;
        font-size: inherit;
    font-size: 100%;
    vertical-align: baseline;
    list-style: none;
}

.tree {
    overflow:hidden 
}
.tree .node {
    padding-left:var(--spacing-05,1.4rem);
    background-color:var(--ui-01);
    color:var(--color,#525252)
}
.tree .node:focus {
    outline:0
}
.tree .node:focus>.node__label {
    /*outline:2px solid var(--focus,#0f62fe);*/
    /*outline-offset:-2px*/
}
@media screen and (prefers-contrast) {
.tree .node:focus>.node__label {
        outline-style:dotted
    }
}
.tree .node--disabled:focus>.node__label {
    outline:0
}
.tree .node--disabled,
.tree .node--disabled .node__label:hover,
.tree .node--disabled .node__label:hover .node__label__details {
    background-color:var(--disabled-01,#f4f4f4);
    color:var(--disabled-02,#c6c6c6)
}
.tree .node--disabled .node__icon,
.tree .node--disabled .node__label:hover .node__icon,
.tree .node--disabled .node__label:hover .parent-node__toggle-icon,
.tree .node--disabled .parent-node__toggle-icon {
    fill:var(--disabled-02,#c6c6c6)
}
.tree .node--disabled,
.tree .node--disabled .parent-node__toggle-icon:hover {
    cursor:not-allowed
}
.tree .node__label {
    display:flex;
    min-height:2rem;
    flex:1;
    align-items:center;
    border-radius: var(--border-radius,0);
}
.tree .node__label:hover {
    background-color:var(--hover-color,#f9f9f9);
    color:var(--text-01,#161616)
}
.tree .node__label:hover .node__label__details {
    color:var(--text-01,#161616)
}
.tree .node__label:hover .node__icon,
.tree .node__label:hover .parent-node__toggle-icon {
    fill:var(--icon-01,#161616)
}


.tree .leaf-node {
    display:flex;
    padding-left:var(--spacing-08,2.5rem);
    white-space: nowrap;
}
.tree .leaf-node.node--with-icon {
    padding-left:var(--spacing-07,2rem)
}
.tree .node__label__details {
    display:flex;
    align-items:center
}
.tree .node--with-icon .parent-node__toggle {
    /*margin-right:0*/
}
.tree .parent-node__toggle {
    padding:0;
    border:0;
    margin-right:var(--spacing-03,.5rem)
}
.tree .parent-node__toggle:hover {
    cursor:pointer
}
.tree .parent-node__toggle:focus {
    outline:0
}
.tree .parent-node__toggle-icon {
    fill:var(--icon-02,#525252);
    -webkit-transform:rotate(-90deg);
    transform:rotate(-90deg);
    transition:110ms cubic-bezier(.2,0,.38,.9)
}
.tree .parent-node__toggle-icon--expanded {
    -webkit-transform:rotate(0);
    transform:rotate(0)
}
.tree .node__icon {
    flex-shrink:0;
    margin-right:var(--spacing-03,.5rem);
    fill:var(--icon-02,#525252)
}


.tree .node--selected>.node__label {
    background-color:var(--selected-ui,transparent);
    color:var(--select-color,blue);
}
.tree .node--selected>.node__label fds-icon {
    --color:var(--select-color,blue);
}
.tree .node--selected>.node__label .node__icon,
.tree .node--selected>.node__label .parent-node__toggle-icon {
    fill:var(--icon-01,#161616)
}
.tree .node--selected > .node__label:hover,
.tree .node--selected > .node__label:hover .node__label__details {
    color:var(--selected-hover-color,#525252);
    background-color:var(--selected-hover-bg-color,#f9f9f9);
}
.tree .node--selected > .node__label:hover .node__icon,
.tree .node--selected>.node__label:hover fds-icon,
.tree .node--selected >.node__label:hover .parent-node__toggle-icon {
    --color:var(--selected-hover-color,#525252);
}

.tree .node--active>.node__label {
/*    position:relative; */
}
.tree .node--active>.node__label::before {
    position:absolute;
    top:0;
    left:0;
    width:.25rem;
    height:100%;
    /* background-color:var(--interactive-04,#0f62fe); */
    content:""
}
.tree--compact .node__label {
    min-height:1.5rem
}

  



`;